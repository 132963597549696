import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import GlobalStyles from '../../styles/globalStyles'
import { getUser } from '../../containers/Profile/actions'
import Header from '../Header/Header'
import BreakpointProvider from '../BreakpointProvider'

const Layout = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUser())
  })

  return (
    <>
      <GlobalStyles />
      <BreakpointProvider>
        <Header />
        <main>{children}</main>
      </BreakpointProvider>
    </>
  )
}

export default Layout
