/* eslint-disable no-use-before-define */
import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  StyledUserMenu,
  StyledButton,
  ButtonInner,
  LogoutButton,
  LoginButton,
} from './styles'
import Avatar from '../Avatar/Avatar'
import { logout } from '../../containers/Profile/actions'
// import windowOpener from '../../helpers/windowOpener'

const UserMenu = () => {
  const refElem = useRef(null)
  const [isOpen, setOpen] = useState(false)

  function handleOpen() {
    setOpen(true)
    document.addEventListener('click', handleClickOutside)
  }

  function handleClose() {
    document.removeEventListener('click', handleClickOutside)
    setOpen(false)
  }

  function handleClickOutside(e) {
    if (refElem.current && !refElem.current.contains(e.target)) {
      handleClose(e)
    }
  }

  function toggle(e) {
    if (isOpen) {
      handleClose(e)
    } else {
      handleOpen(e)
    }
  }

  const {
    profile: { isAuthenticated, entity },
  } = useSelector(({ profile }) => ({
    profile,
  }))

  const dispatch = useDispatch()
  // const onTelegramAuth = (user) => {
  //   dispatch(getUser(user))
  // }

  const onLogout = () => {
    handleClose()
    dispatch(logout())
  }

  // const handleWindowOpen = windowOpener(onTelegramAuth)
  const handleWindowOpen = () => {
    const loginUrl = process.env.GATSBY_LOGIN_URL
    window.location.href = loginUrl
  }

  const handleLogin = (e) => {
    e.preventDefault()
    handleWindowOpen(e)
  }

  const photoUrl = entity && entity.photoUrl
  const name = entity && (entity.username || entity.firstName || 'Guest')

  return (
    <>
      {isAuthenticated ? (
        <StyledUserMenu ref={refElem}>
          <StyledButton onClick={toggle}>
            <Avatar photoUrl={photoUrl} username={name} />
          </StyledButton>
          {isOpen && (
            <LogoutButton onClick={onLogout}>
              <ButtonInner>Logout</ButtonInner>
            </LogoutButton>
          )}
        </StyledUserMenu>
      ) : (
        <LoginButton onClick={handleLogin}>
          <ButtonInner>Login</ButtonInner>
        </LoginButton>
      )}
    </>
  )
}

export default UserMenu
