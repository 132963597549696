import React, { createContext, useContext } from 'react'

import useAspectRatio from '../hooks/useAspectRatio'

const defaultValue = {
  windowAspectRatio: 1,
}

const BreakpointContext = createContext(defaultValue)

const BreakpointProvider = ({ children }) => {
  const windowAspectRatio = useAspectRatio()

  const context = { windowAspectRatio }
  return (
    <BreakpointContext.Provider value={context}>
      {children}
    </BreakpointContext.Provider>
  )
}

export const useBreakpoint = () => {
  const context = useContext(BreakpointContext)
  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider')
  }
  return context
}

export default BreakpointProvider
