import { createGlobalStyle } from 'styled-components'
import { colors, breakpoints } from './constants'

const GlobalStyles = createGlobalStyle`
  :root {
    font-size: 16px;
    line-height: 1.3;

    @media (min-width: ${breakpoints.md}) {
      font-size: 16px;
    }
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.3;
    color: ${colors.fontColor};

    @media (min-width: ${breakpoints.md}) {
      font-size: 16px;
    }
  }

  h1 {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;

    @media (min-width: ${breakpoints.md}) {
      font-size: 2.5rem;
    }

    @media (min-width: ${breakpoints.lg}) {
      font-size: 3rem;
    }
  }

  h2 {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;

    @media (min-width: ${breakpoints.md}) {
      font-size: 2.5rem;
    }

    @media (min-width: 992px) {
      font-size: 2.8rem;
    }
  }

  h3 {
    font-size: 1.7rem;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    text-transform: uppercase;


    @media (min-width: ${breakpoints.md}) {
      font-size: 2rem;
    }
  }

  h4 {
    font-size: 1.5rem;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    text-transform: uppercase;
  }

  table {
    border-collapse: collapse;
  }

  table, th, td {
    border: 1px solid black;
  }

  .text-page {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 960px;
    margin-top: 30px;
  }
`

export default GlobalStyles
