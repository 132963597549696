import { useEffect, useState } from 'react'

const DEFAULT_ASPECT_RATIO = 1

const useAspectRatio = () => {
  const [aspectRatio, setAspectRatio] = useState(DEFAULT_ASPECT_RATIO)
  const getWindowAspectRatio = () => {
    if (typeof window === 'undefined') {
      return null
    }
    return window.innerWidth / window.innerHeight
  }

  const handleResize = () => {
    const result = getWindowAspectRatio()
    setAspectRatio(result)
  }

  useEffect(() => {
    handleResize()
  })

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return aspectRatio
}

export default useAspectRatio
