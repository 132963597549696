import styled from 'styled-components'

export const StyledHeader = styled.header`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: auto;
`

export const HeaderContent = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 10px 20px;
`

export const HeaderLogin = styled.div`
  margin-left: auto;
`

export const Logo = styled.span`
  align-self: center;
  font-weight: bold;
`
