import styled from 'styled-components'
import { colors } from '../../styles/constants'

export const StyledUserMenu = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
`

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  min-width: 90px;
  margin-bottom: 1em;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ButtonInner = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  padding: 9px 21px 11px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
`

export const LogoutButton = styled(StyledButton)`
  color: red;
  background-color: ${colors.grey1};
`

export const LoginButton = styled(StyledButton)`
  background-color: ${colors.grey1};
`
