import React from 'react'
import { HeaderContent, HeaderLogin, StyledHeader } from './styles'
import UserMenu from '../UserMenu/UserMenu'

const Header = () => (
  <StyledHeader>
    <HeaderContent>
      <HeaderLogin>
        <UserMenu />
      </HeaderLogin>
    </HeaderContent>
  </StyledHeader>
)

export default Header
