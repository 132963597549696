export const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
}

export const colors = {
  fontColor: '#0a0a0',
  grey1: '#efefef',
}
