import styled from 'styled-components'
import { colors } from '../../styles/constants'

export const StyledAvatar = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

export const StyledContent = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  background-color: ${colors.grey1};
  box-sizing: border-box;
  padding: 9px 21px 11px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
`
