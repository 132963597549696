import React from 'react'
import { StyledAvatar, StyledContent } from './styles'

const Avatar = ({ photoUrl, username }) =>
  photoUrl ? (
    <StyledAvatar src={photoUrl} />
  ) : (
    <StyledContent>{username}</StyledContent>
  )

export default Avatar
